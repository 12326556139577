/* eslint-disable react/jsx-no-literals */
import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, ContentrArea } from '@ttstr/components';

const FormPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <article>
      <Helmet>
        <body className="form-page" />
      </Helmet>

      <Container className="py-5">
        <ContentrArea id="main-content" />
      </Container>
    </article>
  );
};

export default React.memo(FormPage);
