import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { receiveProducts } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { Link } from 'react-router-dom';

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article>
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page" />
      </Helmet>

      <div className="stage-wrapper p-3 d-none">
        <Container className="d-flex align-content-center flex-column">
          <Link to="/product/36370/willkommen-goodbye-fanbox">
            <img
              src={require('./assets/images/jorisbox.jpg')}
              alt="JORIS – WILLKOMMEN GOODBYE TOUR 2022"
              className="w-100 img-fluid mt-3"
            />
          </Link>
          <Row className="justify-content-center">
            <Col md="8" className="mt-3 mb-3 text-center">
              <p className="lead text-uppercase fw-bold">
                <strong>{'Meine allererste Fanbox'}</strong>
                {' – Jetzt vorbestellen!'}
              </p>
              <Link
                to="/product/36370/willkommen-goodbye-fanbox"
                className="btn btn-primary btn-lg text-uppercase fw-bold"
              >
                {'Jetzt Fanbox sichern'}
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="list-page">
        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry id="product-listing" products={products} filters="off" showSubtitle />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
